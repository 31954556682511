<template>

<img id = "gif" :src = "getCondData().img">

</template>

<script>

function getCondData(){
	let date = new Date().getMonth();
	let data = {img: "/dog.gif", gradient: "to right, #000000, #001726, #00314f, #0072b5, #00a1ff"};
	if(date == 11){
		data.img = "/december.gif";
		data.gradient = "to right, #FFFFFF, #73FFFF, #00FFF4";
	}else if(date == 9){
		data.img = "/october.gif";
		data.gradient = "to right, #000000, #2D1300, #6E2D00, #A34200, #D24E00";
	}
	data.gradient = `background: linear-gradient(${data.gradient}); background-size: 400% 400%; animation: grad 15s ease infinite;`;
	return data;
}

export default {
	name: 'App',
	data() {
		return { }
	},
	methods: {getCondData},
	beforeCreate: function(){
		document.body.style = getCondData().gradient;
	}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
}
#gif {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
@keyframes grad {
	0% {background-position: 0% 50%}
	50% {background-position: 100% 50%}
	100% {background-position: 0% 50%}
}
</style>
